import React, {useEffect} from 'react';
import HomeBanner from '../Components/Homepage/HomeBanner';
import HomeContent from '../Components/Homepage/HomeContent';
import HomeFeatured from '../Components/Homepage/HomeFeatured';
import { HomeAbout } from '../Components/Homepage/HomeAbout';
import Links from '../Components/Shared/Links';
import { analytics } from '../firebase/index'


const Home = ({ onCursor, toggleMenu, setToggleMenu, setLinkSection }) => {
  useEffect(() => {
    analytics.logEvent('HomePage_visited')
  })
  return (
    <>
      <HomeBanner onCursor={onCursor} />
      <HomeContent />
      <HomeFeatured
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        onCursor={onCursor}
      />
      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAbout onCursor={onCursor} />
    </>
  );
};

export default Home;
