import React, { useEffect } from 'react';
import { FullWidth } from '../styles/Project/ProjectContentStyles';
import ProjectBanner from '../Components/Project/ProjectBanner';
import ProjectContent from '../Components/Project/ProjectContent';
import Links from '../Components/Shared/Links';
import Card from '../Components/card/index';
import { EctraSpace } from '../styles/globalStyle';
import { JumbotronContainer } from '../containers/metal/index';
import {
  HomeAccordian,
  CopyrightAccordion,
} from '../Components/Homepage/HomeAbout';
import { analytics } from '../firebase/index';

const Metal = ({ onCursor, toggleMenu, setToggleMenu, setLinkSection }) => {
  useEffect(() => {
    analytics.logEvent('Metal_Page_visited');
    setToggleMenu(false);
  }, []);

  return (
    <div>
      <ProjectBanner
        title={'الفن المعدني'}
        noFeaturedVideo={true}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        onCursor={onCursor}
      />
      <JumbotronContainer />

      <Links setLinkSection={setLinkSection} onCursor={onCursor} />
      <HomeAccordian onCursor={onCursor} />
      {/* <CopyrightAccordion onCursor={onCursor} /> */}
    </div>
  );
};

export default Metal;
