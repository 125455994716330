import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

export const HomeFeaturedSection = styled(motion.div)`
  width: 100%;
  height: 200px;
  display: block;
  margin: 97px 0 87px 0;
  position: relative;

  @media (max-width: 768px) {
    height: 200px;
  }
  @media (max-width: 500px) {
    margin: 102px 0 72px 0;
    height: 80px;
  }
  a {
    margin-bottom: 200px;
    position: relative;
    display: block;
  }
`;
export const FeaturedContent = styled(motion.div)`
  height: 480px;
  width: 100%;
  padding: 56px 32px;
  box-sizing: border-box;
  color: ${(props) => props.theme.text};

  @media (max-width: 768px) {
    height: 200px;
    padding: 56px 0px;
  }

  @media (max-width: 500px) {
    height: 80px;
    padding: 56px 0px;
  }

  h3 {
    margin: 40px 0 0;
    font-size: 1.3rem;
  }

  .meta {
    display: flex;

    h4 {
      &:last-child {
        margin-left: 1rem;
      }
    }
  }

  .featured-title {
    direction: rtl;
    right: 30px;
    position: absolute;
    // bottom: -50px;
    bottom: 0;
    font-size: 7rem;
    font-weight: 200;
    line-height: 100px;
    text-transform: uppercase;
    margin: 0;
    left: 30px;
    @media (max-width: 768px) {
      font-size: 5rem;
    }
    @media (max-width: 500px) {
      font-size: 2.6rem !important;
      left: 20px;
      bottom: -5px;
    }
    &.vision {
      font-size: 5.8rem;
      left: 34px;
      bottom: -10px;
      @media (max-width: 500px) {
        font-size: 2.8rem !important;
        line-height: 40px;
        bottom: -40px;
        left: 20px;
      }
      @media (max-width: 380px) {
        font-size: 2.5rem !important;
        line-height: 40px;
        bottom: -40px;
        left: 20px;
      }
  }
      @media (max-width: 1000px) {
      font-size: 4rem !important;
      line-height: 70px;
    }
    @media (max-width: 500px) {
      font-size: 2.6rem !important;
      line-height: 40px;
    }
    }
    .arrow {
      width: 120px;
      height: 80px;
      display: block;
      position: relative;
      overflow: hidden;

      svg {
        position: absolute;
        top: 16px;
        left: -48px;
        width: 108px;

        path {
          fill: ${(props) => props.theme.text};
        }
      }
    }
  }
`;
export const FeaturedVideo = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 480px;
  top: 0;
  display: block;
  overflow: hidden;
`;
export const FeaturedProject = styled.div`
  margin-top: 200px;

  button {
    color: #fff;
    position: relative;
    padding: 20px;
    display: block;
    text-align: left;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 600;
    border: none;
    background: ${(props) => props.theme.red};

    span {
      display: block;
      margin-right: 100px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      width: 35px;
      height: 7px;
      display: block;
      background: #fff;
      transform: translateY(-50%);
    }

    &:before {
      margin-top: -8px;
    }

    &:after {
      margin-top: 8px;
    }
  }
`;
