import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
import useWindowSize from '../../hooks/useWindowSize';

export function JumbotronContainer() {
  const size = useWindowSize();
  return (
    <>
      {/* Jumbotron 1 */}
      <Jumbotron.Container className='contact'>
        <Jumbotron direction='row'>
          <Jumbotron.Pane style={{ width: '100%' }}>
            <Jumbotron.Text>
              {size.width < 760 ? (
                <p
                  style={{
                    width: '100%',
                    marginLeft: '5px',
                    marginBottom: '60px',
                  }}
                >
                  {/* نسعد باستقبال كافة استفساراتكم وطلباتكم، لا تتردد بالاتصال
                  بنا. */}
                  يسعدنا استقبال جميع الاستفسارات والطلبات. لا تتردد في التواصل
                  معنا.
                </p>
              ) : size.width > 760 ? (
                <p
                  style={{
                    width: '100%',
                    marginLeft: '5px',
                    marginBottom: '60px',
                  }}
                >
                  {/* نسعد باستقبال كافة استفساراتكم وطلباتكم، لا تتردد بالاتصال
                  بنا. */}
                  يسعدنا استقبال جميع الاستفسارات والطلبات. لا تتردد في التواصل
                  معنا.
                </p>
              ) : size.width < 391 ? (
                <p
                  style={{
                    width: '100%',
                    marginLeft: '5px',
                    marginBottom: '60px',
                    border: '1px solid white',
                  }}
                >
                  {/* نسعد باستقبال كافة استفساراتكم وطلباتكم، لا تتردد بالاتصال
                  بنا. */}
                  يسعدنا استقبال جميع الاستفسارات والطلبات. لا تتردد في التواصل
                  معنا.
                </p>
              ) : (
                <></>
              )}
            </Jumbotron.Text>
          </Jumbotron.Pane>
        </Jumbotron>
      </Jumbotron.Container>
    </>
  );
}
