import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
// import metal1 from '../../assets/img/metal/metal1.jpg';
// import metal2 from '../../assets/img/metal/metal2.jpg';

export function JumbotronContainer() {
  const metal1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/681421.d5b4a9bd.jpg';
  const metal2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg';

  return (
    <>
      <div className='Desktop-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container className='metal1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    إن جاز التعبير، يتم صناعة لوحاتي الفنية بشكل خاص من المعدن،
                    الحديد، النحاس، النحاس الأصفر، البرونز والألمنيوم! ~....
                    تصاميم غريبة ومعقدة يصل ارتفاعها من 5 قدم (154.4 سم) إلى +
                    10 أقدام (304.8 + سم)، وتبلغ سماكتها حوالي 1 إنش (2.52 سم).
                  </p>
                  <p>إنها أكبر وأضخم منحوتات في العالم!</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={metal1}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/681421.d5b4a9bd.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='metal2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    سوف تضيف تشطيبات الصقل الباهظة توقيعاً خاصاً وذوقاً رفيعاً
                    إن كنت تود ذلك؛ لكنني مع ذلك أفضل البساطة والصلب الخام
                    الصناعي البحت.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={metal2}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='metal3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    مذهلة وفريدة من نوعها῀! فهذا ما أتخصص في انتاجه وابداعه.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='metal1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    إن جاز التعبير، يتم صناعة لوحاتي الفنية بشكل خاص من المعدن،
                    الحديد، النحاس، النحاس الأصفر، البرونز والألمنيوم! ~....
                    تصاميم غريبة ومعقدة يصل ارتفاعها من 5 قدم (154.4 سم) إلى +
                    10 أقدام (304.8 + سم)، وتبلغ سماكتها حوالي 1 إنش (2.52 سم).
                  </p>
                  <p>إنها أكبر وأضخم منحوتات في العالم!</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={metal1}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/681421.d5b4a9bd.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal1.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='metal2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    سوف تضيف تشطيبات الصقل الباهظة توقيعاً خاصاً وذوقاً رفيعاً
                    إن كنت تود ذلك؛ لكنني مع ذلك أفضل البساطة والصلب الخام
                    الصناعي البحت.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={metal2}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/284170.58016c361.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/metal2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='metal3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    مذهلة وفريدة من نوعها῀! فهذا ما أتخصص في انتاجه وابداعه.
                  </p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
