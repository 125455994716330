import styled from 'styled-components/macro';

export const NavigationWapper = styled.div`
  min-height: 1460px;
  @media (max-width: 768px) {
    min-height: 1200px;
  }
  @media (max-width: 500px) {
    min-height: 740px;
  }
`;

export const ProgressBar = styled.div`
  position: fixed;
  top: 12%;
  left: 5.2%;
  background: ${(props) => props.theme.text};
  // background: rgba(234, 231, 231);
  width: 1px;
  z-index: 1;
  max-height: 76.5%;
  // max-height: calc(100%-70px);

  @media (max-width: 1380px) {
    max-height: 74%;
  }
  @media (max-width: 500px) {
    max-height: 78%;
    // max-height: calc(100% - 70px);
  }
`;

export const ProjectPreviewWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;

  .project_preview {
    position: fixed;
    background-size: contain !important;
    // top: 12%;
    // left: 50%;
    // width: 600px;
    // height: 400px';
    top: -44%;
    left: -34.5%;
    width: 100%;
    height: 200%;
    transform: scale(0.4);
    overflow: 'hidden';
    @media (max-width: 1380px) {
      left: -33.8%;
    }

    @media (max-width: 768px) {
      max-width: 330px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }
`;

export const NavigationList = styled.ul.attrs((props) => ({
  hoverColor: props.currentTheme,
}))`
  margin-top: 140px;
  margin-left: 82px;
  margin-bottom: 200px;
  list-style: none;
  position: absolute;
  color: ${(props) => props.hoverColor};
  @media (max-width: 768px) {
    margin: 150px 0 150px 0;
  }
  @media (max-width: 500px) {
    margin: 135px 0 150px 0;
  }


  li {
    display: block;
    user-select: none;
    margin: 20px 0;
    @media (max-width: 768px) {
      margin: 16px 0;
    }

    a {
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      font-size: 70px;
      text-decoration: none;
      color: ${(props) =>
        props.hoverColor === 'dark'
          ? 'rgba(255,255,255,0.3)'
          : 'rgba(0,0,0,0.3)'};
      @media (max-width: 768px) {
        font-size: 3rem;

        color: ${(props) =>
          props.hoverColor === 'dark' ? '#fff' : 'rgba(0,0,0,1)'};
      }
      @media (max-width: 500px) {
        font-size: 1.5rem;
      }

    }

    span {
      position: relative;
      display: block;

      &:before {
        width: 0%;
        overflow: hidden;
        position: absolute;
        content: attr(data-text);
        line-break: loose;
        white-space: nowrap;
        transition: all 1s cubic-bezier(0.84, 0, 0.08, 0.99);
      }

      &:hover:before {
        width: 100%;
        color: ${(props) =>
          props.hoverColor === 'dark' ? '#fff' : 'rgb(0,0,0)'};

      }
    }
`;

export const NavigationItem = styled.li``;

export const ProjectOverlay = styled.div``;
