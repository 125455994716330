import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

export const Nav = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  // background: #f42835;
  background: linear-gradient(
    to bottom,
    #9c192f,
    20%,
    #f42835,
    40%,
    #aa0034,
    70%,
    #eb002a,
    90%,
    #b1212b 100%
  );
  color: #000;
  z-index: 1002;
  overflow: hidden;
`;

export const NavHeader = styled.div`
  top: 72px;
  position: relative;

  h2 {
    color: ${(props) => props.theme.background};
  }
`;
export const CloseNav = styled.div`
  button {
    transform-origin: center;
    border: none;
    padding: 20px;
    background: none;
    outline: none;

    span {
      width: 44px;
      height: 3px;
      display: block;
      background: #ffffff;
      // background: ${(props) => props.theme.text};
      transform: translate(-50%, -50%);
      margin: 8px;

      &.line1 {
        transform: rotate(45deg) translate(1px, 2px);
      }

      &.line2 {
        transform: rotate(-45deg) translate(7px, -6px);
      }
    }
  }
`;

export const NavList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  // align-items: center;

  ul {
    padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media (max-width: 768px) {
        overflow-y: scroll;
      }
      @media (max-width: 500px) {
        justify-content: center;
        overflow-y: scroll;
    }

    li {
      list-style: none;
      font-size: 2.3rem;
      text-transform: uppercase;
      font-weight: 300;
      height: 80px;
      line-height: 76px;
      overflow: hidden;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        font-size: 1.9rem;
        height: 100px;
        line-height: 46px;
        // margin: 20px 0;
        margin: 5px 0;
      }
      @media (max-width: 500px) {
        // height: 88px;
        height: 83px;
        margin: 5px 0;
        white-space: nowrap;
        font-size: 1.5rem;
      }
      @media (max-width: 350px) {
        height: 88px;
        white-space: nowrap;
        font-size: 1.4rem;
      }

      .link {
        color: #fff;
        position: relative;
        display: flex;
        align-items: center;

        .arrow {
          height: 46px;
          margin-right: 8px;
        }
      }

      svg {
        width: 100px;
        height: 40px;
        display: flex;
    }

        path {
          fill: ${(props) => props.theme.background};
        }
      }
    }
  }
`;
export const NavFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 56px 0px;

  p {
    color: ${(props) => props.theme.background};
  }

  svg path {
    fill: ${(props) => props.theme.background};
  }
`;

export const NavVideos = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  left: 25%;
  z-index: -1;
  height: 100%;
  width: 100%;
  // background: #000;
  display: flex;
  align-items: center;

  .reveal {
    width: 100%;
    // background: #f42835;
    background: linear-gradient(
      to bottom,
      #9c192f,
      20%,
      #f42835,
      40%,
      #aa0034,
      70%,
      #eb002a,
      90%,
      #b1212b 100%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .img {
    // background: #000;
    position: absolute;
    // height: 100%;
    margin: 0;
    z-index: -1;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // width: 82%;
    width: 100%;
    // height: 110%;
    transform: translateX(100%);
    // transform: scale(1.1) translateX(4.6%);

    img {
      max-width: 68%;
      width: 100%;
      max-height: 91%;
      height: 100%;

      // &.artist {
      //   transform: scale(1.1) translateX(4.6%);
      // }

      &.meaning {
        background: ${(props) => props.theme.background};
        // background: #000;
        transform: scale(0.8);
      }

      // &.philosophy {
      //   transform: scale(1.1) translateX(4.6%);
      // }

      // &.vision {
      //   transform: scale(1.1) translateX(4.6%);
      // }

      // &.beauty {
      //   transform: scale(1.1) translateX(4.6%);
      // }

      // &.metal {
      //   transform: scale(2) translateX(25%);
      // }

      // &.commitment {
      //   transform: scale(1.1) translateX(4.6%);
      // }
    }
  }
  .video {
    // height: 100%;
    display: flex;
    height: auto;
  }
  .video video {
    max-width: 80%;
  }
`;
