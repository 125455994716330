import styled from 'styled-components/macro';

export const ProjectContentSection = styled.div`
  margin: 160px 0 0;
`;
export const ProjectContentWrapper = styled.div`
  margin-top: -115px;
  margin-bottom: 75px;
  @media (max-width: 500px) {
    margin-bottom: -5px;
  }
  p {
    color: ${(props) => props.theme.text};
    width: 40%;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 150%;
    margin-left: 124px;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
      margin-left: 0;
      width: 95% !important;
      font-size: 1.2rem;
    }

    @media (max-width: 500px) {
      margin-left: 0;
      width: 95% !important;
      font-size: 1.1rem;
    }
  }

  ul {
    margin: 0;

    li {
      color: ${(props) => props.theme.text};
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 150%;
      margin-left: 124px;
      letter-spacing: 0.5px;
      @media (max-width: 768px) {
        margin-left: 0;
      }
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
  }
`;

export const ProjectCredits = styled.div`
  margin: 80px 0;
`;

export const Credit = styled.div`
  margin-left: 124px;
  color: ${(props) => props.theme.text};

  span {
    display: block;
    font-weight: 700;
  }
`;

export const FullWidth = styled.div`
  p {
    width: 80%;

    span {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  h6 {
    white-space: pre;
  }

  h2 {
    color: white;
    text-align: end;
    margin-left: 124px;
    font-size: 1.4rem;
    font-weight: 400;
    width: 80%;

    span {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
`;
