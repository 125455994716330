import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

//Banner
export const Banner = styled.div`
  background: ${(props) => props.theme.background};
  height: 100vh;
  width: 100%;
  position: relative;
`;

export const Video = styled.div`
  height: 100%;
  width: 100%;

  video {
    object-fit: cover;
  }
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
`;

export const BannerTitle = styled(motion.h1)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  color: ${(props) => props.theme.text};
  pointer-events: none;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  width: 100%;
  // width: 1214px;

  @media (max-width: 768px) {
    width: 800px;
  }

  @media (max-width: 600px) {
    width: 600px;
  }

  @media (max-width: 500px) {
    width: 400px;
    transform: scale(1.35);
    overflow: hidden;
  }
  @media (max-width: 418px) {
    width: 400px;
    transform: scale(1.33);
    overflow: hidden;
  }
  @media (max-width: 410px) {
    width: 400px;
    transform: scale(1.3);
    overflow: hidden;
  }
  @media (max-width: 400px) {
    width: 400px;
    transform: scale(1.27);
    overflow: hidden;
  }
  @media (max-width: 385px) {
    width: 400px;
    transform: scale(1.22);
    overflow: hidden;
  }
  @media (max-width: 370px) {
    width: 400px;
    transform: scale(1.16);
    overflow: hidden;
  }
  @media (max-width: 355px) {
    width: 400px;
    transform: scale(1.12);
    overflow: hidden;
  }

  svg {
    overflow: hidden;
    width: 100%;
    // width: 1214px;
    // scale: 1.8;

    @media (max-width: 768px) {
      width: 800px;
    }

    @media (max-width: 600px) {
      width: 600px;
    }

    @media (max-width: 500px) {
      width: 400px;
      transform: scale(1.35);
      overflow: hidden;
    }
    @media (max-width: 418px) {
      width: 400px;
      transform: scale(1.33);
      overflow: hidden;
    }
    @media (max-width: 410px) {
      width: 400px;
      transform: scale(1.3);
      overflow: hidden;
    }
    @media (max-width: 400px) {
      width: 400px;
      transform: scale(1.27);
      overflow: hidden;
    }
    @media (max-width: 385px) {
      width: 400px;
      transform: scale(1.22);
      overflow: hidden;
    }
    @media (max-width: 370px) {
      width: 400px;
      transform: scale(1.16);
      overflow: hidden;
    }
    @media (max-width: 355px) {
      width: 400px;
      transform: scale(1.12);
      overflow: hidden;
    }
  }
`;

export const Headline = styled(motion.span)`
  display: block;
  font-size: 7.3rem;
  line-height: 0.76;
  font-weight: 300;
  font-family: 'caviar_dreams', sans-serif;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 5rem;
  }

  @media (max-width: 500px) {
    font-size: 3rem;
  }
`;
export const SubHeadline = styled(motion.span)`
  display: block;
  font-size: 2rem;
  line-height: 1;
  font-family: 'Helvertica Neue', sans-serif;
  letter-spacing: 5px;
  font-weight: 200;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`;

export const HomeContentSection = styled(motion.div)`
  // margin-bottom: 200px;
  margin-bottom: 90px;
  margin-top: 70px;
  @media (max-width: 500px) {
    // margin-bottom: 120px;
    margin: 50px 0;
  }
`;
export const Content = styled.h2`
  width: 53%;
  font-size: 2.3rem;
  font-weight: 400;
  margin-left: 124px;
  color: ${(props) => props.theme.text};

  &.homePage {
    width: 100%;

    @media (max-width: 500px) {
      font-size: 1rem;
    }

    // @media (max-width: 400px) {
    //   font-size: 1rem;
    // }

    // @media (max-width: 360px) {
    //   font-size: 0.9rem;
    // }

    span {
      font-weight: 700;
      font-size: 2.5rem;

      @media (max-width: 500px) {
        font-size: 1.1rem;
      }
    }
  }
  @media (max-width: 1380px) {
    font-size: 2.2rem;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
    width: 95%;
    margin-left: 0;
  }
  @media (max-width: 500px) {
    font-size: 1.8rem;
    width: 95%;
    margin-left: 0;
  }
`;

/*HomeFeatured*/
export const HomeFeaturedSection = styled(motion.div)`
  // margin-bottom: 200px;
  margin-bottom: -45px;
  position: relative;

  @media (max-width: 500px) {
    // margin-bottom: 100px;
    margin-bottom: -20px;
  }

  a {
    margin-bottom: 200px;
    position: relative;
    display: block;

    @media (max-width: 500px) {
      margin-bottom: 100px;
    }
  }
`;
export const FeaturedContent = styled(motion.div)`
  height: 480px;
  width: 100%;
  padding: 56px 124px;
  box-sizing: border-box;
  color: ${(props) => props.theme.text};
  @media (max-width: 500px) {
    // height: 200px;
    height: 180px;
  }

  h3 {
    font-size: 1.3rem;
  }

  .meta {
    display: flex;

    h4 {
      &:last-child {
        margin-left: 1rem;
      }
    }
  }

  .featured-title {
    position: absolute;
    bottom: -128px;
    font-size: 7rem;
    font-weight: 300;
    line-height: 90px;
    margin: 0;
    @media (max-width: 500px) {
      font-size: 3rem;
      bottom: -40px;
      left: 15px;
    }

    .arrow {
      width: 120px;
      height: 95px;
      display: block;
      position: relative;
      overflow: hidden;
      left: 35px;
      @media (max-width: 500px) {
        // display: none;
        width: 40px;
        height: 25px;
        position: absolute;
        left: 15px;
        top: 70px;
      }

      svg {
        position: absolute;
        top: 6px;
        left: -40px;
        width: 110px;
        height: 25px;

        path {
          fill: ${(props) => props.theme.text};
        }

        @media (max-width: 500px) {
          top: 0px;
          left: -7px;
          width: 30px;
          height: 15px;
          // scale: 0.28;
        }
      }
    }
  }
`;
export const FeaturedVideo = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 480px;
  top: 0;
  display: block;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    align-items: normal;
  }

  @media (max-width: 500px) {
    // height: 200px;
    height: 180px;
  }
`;
export const FeaturedProject = styled.div`
  margin-top: 200px;

  button {
    color: #fff;
    position: relative;
    padding: 20px;
    display: block;
    text-align: left;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 600;
    border: none;
    background: ${(props) => props.theme.red};

    span {
      display: block;
      margin-right: 100px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 20px;
      width: 35px;
      height: 7px;
      display: block;
      background: #fff;
      transform: translateY(-50%);
    }

    &:before {
      margin-top: -8px;
    }

    &:after {
      margin-top: 8px;
    }
  }
`;

export const ProjectFeatured = styled.div`
  width: 100%;
  height: 480px;
  display: block;
  margin: 173px 0 86px;

  .featured-title {
    bottom: -50px;
  }
`;

/*HomeAboutSection*/

export const HomeAboutSection = styled.div`
  margin-top: -20px;
  margin-bottom: 85px;

  &.copyrightAccordian {
    margin-top: -85px;
  }

  &.copyright_section {
    margin: 0;
  }

  &.contact {
    margin-top: 72px;
  }
  &.copyright_home_about {
    margin-top: 1rem;
  }
  @media (max-width: 500px) {
    margin-bottom: 68px;
    margin-top: -25px;
  }
  @media (max-width: 768px) {
    .flex {
      flex-direction: column;
    }
  }
`;
export const About = styled.div`
  width: 100%;

  h2 {
    width: 60%;
    font-size: 2.3rem;
    font-weight: 400;
    margin-left: 124px;
    color: ${(props) => props.theme.text};

    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
    }
    @media (max-width: 500px) {
      font-size: 1.8rem;
    }
  }

  p {
    max-width: 444px;
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 124px;
    color: ${(props) => props.theme.text};
    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
    }
  }
`;
export const Services = styled.div`
  align-self: flex-start;
  // width: 14%;
  position: relative;
  width: 100%;
  left: 49%;

  &.copyrightHome {
    left: -3px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &.copyrightPageAccordian {
    left: -5px;
    top: 20px;
  }

  @media (max-width: 1000px) {
    left: 0;
  }

  // @media (max-width: 1485px) {
  //   width: 16%;
  // }
  // @media (max-width: 1300px) {
  //   width: 18%;
  // }
  // @media (max-width: 1150px) {
  //   width: 20%;
  // }
  // @media (max-width: 1150px) {
  //   width: 25%;
  // }
  // @media (max-width: 768px) {
  //   width: 70%;
  // }
`;

/*Accordion*/
export const AccordionHeader = styled(motion.div)`
  width: 100%;

  color: ${(props) => props.theme.red};
  // color: ${(props) => props.theme.text};
  // background-clip: text;
  // background-image: linear-gradient(
  //   to right,
  //   rgb(156, 25, 47),
  //   20%,
  //   rgb(235, 0, 42),
  //   35%,
  //   rgb(170, 0, 52),
  //   50%,
  //   rgb(235, 0, 42),
  //   75%,
  //   rgb(177, 33, 43)
  // );
  // color: transparent;
  height: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  font-weight: 600;
  // font-size: 1.15rem;
  font-size: 1.4rem;
  margin: 8px 0;

  &.copyright {
    justify-content: flex-start;
    color: ${(props) => props.theme.red} !important;
    gap: 8px;
    align-items: flex-start;

    svg:hover {
      path {
        fill: ${(props) => props.theme.red};
      }
      g {
        fill: ${(props) => props.theme.red};
      }
    }

    @media (max-width: 500px) {
      margin: 20px 0;
    }
    @media (max-width: 500px) {
      font-size: 1.1rem;
      justify-content: flex-start;
      margin: 30px 0;
      svg {
        width: 30px !important;
      }
    }
  }
  &.copyright:hover {
    div {
      color: ${(props) => props.theme.text};
    }
    svg {
      path {
        fill: url(#myGradient);
      }
      g {
        fill: url(#myGradient);
      }
    }
  }
`;
export const AccordionIcon = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 8px;
  position: relative;

  &.copyright_accoudian_icon {
    margin-left: -5px;
    margin-right: 0;
  }

  span {
    width: 16px;
    height: 4px;
    // background: ${(props) => props.theme.red};
    background: linear-gradient(
      to right,
      rgb(156, 25, 47),
      4%,
      rgb(235, 0, 42),
      50%,
      rgb(170, 0, 52)
    );
    transition: 0.1s ease-in-out;
  }
`;
export const AccordionContent = styled(motion.div)`
  overflow: hidden;
  padding-left: 40px;

  &.accordionContentCopyright {
    text-align: start;
    // width: 50%;
    width: 400px;
    justify-self: center;
    padding-left: 115px;

    @media (max-width: 1000px) {
      width: 75%;
      padding-left: 35px;
    }
    @media (max-width: 500px) {
      width: 88%;
      padding-left: 35px;
    }
  }

  span {
    width: 100%;
    margin: 8px 0;
    // font-size: 0.875rem;
    font-size: 1.1rem;
    display: flex;
    align-item: center;
    justify-content: flex-start;
    font-weight: 400;
    color: ${(props) => props.theme.text};
    // color: #aa0034;

    &.copyrightContainer {
      margin: 30px 0 0 0;
    }

    div {
      margin-bottom: 10px;

      &.copyrightContentHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        @media (max-width: 500px) {
          font-size: 0.9rem;
        }
      }
      &.copyrightContent {
        @media (max-width: 500px) {
          font-size: 0.8rem;
        }
      }
      &.click {
        margin: 0;
      }
    }

    &.home {
      width: 100%;
      margin: 8px 0;
      font-size: 1.1rem;
      display: block;
      font-weight: 400;
      color: ${(props) => props.theme.text};

      .span {
        color: ${(props) => props.theme.text};
        display: contents;
        font-size: 1.3rem;
        font-weight: 700;
      }
    }
  }
  span:hover {
    color: ${(props) => props.theme.red};
    svg {
      path {
        fill: url(#myGradient);
      }
      g {
        fill: url(#myGradient);
      }
    }
  }
  span.home:hover {
    // color: ${(props) => props.theme.red};
    .span {
      color: ${(props) => props.theme.red};
    }
  }
`;

export const ExtraSpace = styled.div`
  margin-left: 45%;

  @media (max-width: 768px) {
    margin-left: 0%;
    text-align: end;
  }
  @media (max-width: 500px) {
    // margin-left: 50%;
    line-height: 200%;
  }
`;
