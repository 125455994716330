import React from 'react';
import { Container, Flex } from '../../styles/globalStyle';
import { Link } from 'react-router-dom';
import { LinksSection } from '../../styles/LinksStyles';

const Links = ({ onCursor, setLinkSection }) => {
  return (
    <LinksSection
      onMouseEnter={() => setLinkSection(true)}
      onMouseLeave={() => setLinkSection(false)}
    >
      <Container className={'links'}>
        <Flex spaceBetween className={'flex'}>
          <Link
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={() => onCursor()}
            to={'/teaser'}
          >
            الإعلان التشويقي
          </Link>
          <Link
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={() => onCursor()}
            to={'/main-video'}
          >
            الرئيسية
          </Link>
          <Link
            onMouseEnter={() => onCursor('pointer')}
            onMouseLeave={() => onCursor()}
            to={'/Scenes'}
          >
            المشاهد
          </Link>
        </Flex>
      </Container>
    </LinksSection>
  );
};

export default Links;
