import React, { useRef, useState } from 'react';
import Jumbotron from '../../Components/jumbotron/index';

// import video1 from '../../assets/video/video1.mp4';
// import video2 from '../../assets/video/video2.mp4';
// import video3 from '../../assets/video/video3.mp4';
// import video4 from '../../assets/video/video4.mp4';
// import video5 from '../../assets/video/video5.mp4';
// import mobileVideo3 from '../../assets/video/mobile/mobileVideo3.mp4';
// import mobileVideo4 from '../../assets/video/mobile/mobileVideo4.mp4';
// import visionPhilanthropy1 from '../../assets/img/vision&philanthropy/vision&philanthropy1.jpg';
// import visionPhilanthropy2 from '../../assets/img/vision&philanthropy/vision&philanthropy2.jpg';
// import visionPhilanthropy3 from '../../assets/img/vision&philanthropy/vision&philanthropy3.jpg';
// import visionPhilanthropy4 from '../../assets/img/vision&philanthropy/vision&philanthropy4.jpg';
// import AO_Logo_on_black from '../../assets/img/vision&philanthropy/AO_Logo_on_black.png';
// import AO_Logo_on_white from '../../assets/img/vision&philanthropy/AO_Logo_on_white.png';

import { useGlobalStateContext } from '../../context/globalContext';
import useWindowSize from '../../hooks/useWindowSize';
import './vision.css';

export function JumbotronContainer() {
  const { currentTheme } = useGlobalStateContext();
  const size = useWindowSize();
  const [toggle, setToggle] = useState(false);
  const visionPhilanthropy1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/915689.afcf01867.jpg';
  const visionPhilanthropy2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/814315.e2a28988.jpg';
  const visionPhilanthropy3 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/202975.e43a1730.jpg';
  const visionPhilanthropy4 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/470148.41d73f57.jpg';

  const video1 =
    'https://player.vimeo.com/video/671578638?h=a47c0a4d23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1';
  const video2 =
    'https://player.vimeo.com/video/671578429?h=5d93e73319&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1';
  const video3 =
    'https://player.vimeo.com/video/671578577?h=53ee1a0d72&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1';
  const video4 =
    'https://player.vimeo.com/video/671578494?h=782f79888d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1';
  const video5 =
    'https://player.vimeo.com/video/671578473?h=1b5721003d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1';

  const AO_Logo_on_black =
    'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png';
  const AO_Logo_on_white =
    'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png';

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const videoRef5 = useRef(null);
  const ripple = useRef(null);

  setTimeout(function () {
    // toggle && console.log('Delayed True')
    toggle && setToggle(!toggle);
  }, 500);

  // for videos, put a 'video' named folder in assets add videos with these names

  return (
    <div>
      <div className='Mobile-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <div className='vision1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    كثير من الأعمال التي أقوم بها والمرتبطة ارتباطاً وثيقاً
                    بنطاق رؤيتي الرامية إلى المساعدة في انهاء الفقر المدقع
                    والجوع الذي يعاني منه العالم وخاصة أطفالنا.
                  </p>
                  <p>
                    أحث على بناء شراكات وعلاقات مع أفراد خاصين من جميع أنحاء
                    العالم تكون لديهم الرغبة في تقديم المساعدة لحل هذه المعضلة
                    التي تعاني منها البشرية جمعاء.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy1.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/915689.afcf01867.jpg'
                    visionPhilanthropy1
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Video 1 */}

          <div className='video1'>
            <div>
              <Jumbotron.Video
                className='moreMarginTop'
                style={{ height: 'auto' }} //temprory
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578638?h=a47c0a4d23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='814064.3b2caa22.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef1.current.muted = !videoRef1.current.muted)}
          >
            unMute
          </button> */}
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='vision2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    نحن نعيش في عالم يتسم بالرفاهية والرخاء المعيشي، في عالم لا
                    يجب أن تكون مسألة افتقار القوت قضية شائكة، إلا أننا نرى كل
                    يوم الكثير من حول العالم يعانون بشدة من الفقر والجوع.
                  </p>
                  <p>
                    كي أكون قدوةً ومثالاً يتبعه الجميع، أتعهد بأن يكون "الاحسان/
                    الصدقة" جزء كبير من عوائدي التي أتبرع بها في صندوق التمويل
                    وسوف تتجه نحو حل هذه المشكلة من أجل التخفيف من المعاناة.
                  </p>
                  <p>
                    وجب علينا الشعور بالمسؤولية تجاه إخواننا البشر بغض النظر عن
                    العرق، العقيدة أو الدين..
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/814315.e2a28988.jpg'
                    visionPhilanthropy2
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Video 2 */}

          <div className='video2'>
            <div>
              {/* <Jumbotron.VideoText classNmae={(size.width > 768 && size.width < 1000) ? 'video2above768' : null}> */}
              <Jumbotron.VideoText className='video2above768'>
                {size.width > 720 ? (
                  <p style={{ marginBottom: '40px', marginTop: '35px' }}>
                    تكمن الفضيلة الرئيسية لريادة الأعمال الاجتماعية في "فن
                    العطاء"
                    {'   '}
                    {size.width < 330 ? (
                      <ectraSpace style={{ marginLeft: '20px' }} />
                    ) : (
                      <ectraSpace style={{ marginLeft: '40px' }} />
                    )}
                    {/* ~ Sara Al Madani */}
                  </p>
                ) : size.width > 530 ? (
                  <p style={{ marginBottom: '50px', lineHeight: '130%' }}>
                    تكمن الفضيلة الرئيسية لريادة الأعمال الاجتماعية في "فن
                    العطاء"
                    {'   '}
                    {size.width < 330 ? (
                      <ectraSpace style={{ marginLeft: '20px' }} />
                    ) : (
                      <ectraSpace style={{ marginLeft: '40px' }} />
                    )}
                    {/* ~ Sara Al Madani */}
                  </p>
                ) : (
                  <p>
                    تكمن الفضيلة الرئيسية لريادة الأعمال الاجتماعية في "فن
                    العطاء"
                    {size.width < 415 ? (
                      <ectraSpace style={{ marginLeft: '20px' }} />
                    ) : size.width < 410 ? (
                      <ectraSpace style={{ marginLeft: '100px' }} />
                    ) : size.width < 368 ? (
                      <ectraSpace style={{ marginLeft: '60px' }} />
                    ) : size.width < 330 ? (
                      <ectraSpace style={{ marginLeft: '100px' }} />
                    ) : (
                      <ectraSpace style={{ marginLeft: '40px' }} />
                    )}
                    {/* {(size.width > 411) ? (
                      <span>
                        ~ Sara Al Madani
                      </span>
                    ) : ( */}
                    {/* // <span> */}
                    {/* {size.width < 391 && size.width > 372 ? (
                      <p>~ Sara Al Madani</p>
                    ) : (
                      <>~ Sara Al Madani</>
                    )} */}
                    {/* //   </span> */}
                    {/* // )} */}
                  </p>
                )}
                {/* {(size.width < 411) ? (
                  <p className={size.width < 1000 ? 'margin-10' : ''}>
                    ~ Sara Al Madani
                  </p>
                ) : (
                  <p style={{ display: "none" }} className={size.width < 1000 ? 'margin-10' : ''}>
                    ~ Sara Al Madani
                  </p>
                )} */}
              </Jumbotron.VideoText>
              <Jumbotron.Video
                className={size.width < 1000 ? 'margin_vid2' : ''}
                style={{ height: 'auto' }} //temprory
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578429?h=5d93e73319&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='513406.74833a66.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef2.current.muted = !videoRef2.current.muted)}
          >
            unMute
          </button> */}
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='vision3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    الجزء الأعظم من القيم الروحية التي أتمتع بها هو جمع الناس مع
                    بعضهم البعض في روح من العطاء، مع العلم إننا بحاجة إلى
                    التفكير بوعي شامل كأسرة إنسانية واحدة وليس كفرد واحد من أجل
                    القدرة على حل مشاكل العالم.
                  </p>
                  <p>
                    يرجى مساعدتي ومساعدة العالم لأن يكونوا مع بعضهم البعض كأسرة
                    إنسانية بوعي جماعي من أجل تحقيق هذا الحلم إلى واقع مزهر.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy3.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/202975.e43a1730.jpg'
                    visionPhilanthropy3
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Video 3 */}

          <div className='video3'>
            <div>
              <Jumbotron.VideoText>
                <p className='moreLineHeight'>
                  يتردد صدى إيماني ورؤيتي في قيمي الأخلاقية لجلب القوت والغذاء
                  لهؤلاء الذين هم بحاجة ماسة إليه عبر الاهتزاز الإيجابي الذي
                  سينبعث منه فني.
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                className={size.width < 1000 ? 'margin_vid3' : 'moreMarginTop'}
                style={{ height: 'auto' }} //temprory
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578577?h=53ee1a0d72&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='438201.12a0e317.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef3.current.muted = !videoRef3.current.muted)}
          >
            unMute
          </button> */}
            </div>
          </div>

          {/* Jumbotron 4 */}
          <div className='vision4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    أبتكر الفن مع رؤية تجميل العالم من حولنا بطرق متعددة، رائعة
                    وذات مغزى.. إلى الأفراد الذين حالفهم الحظ لشراء وتقدير هذا
                    الفن وإلى العديد من الأفراد الذي لم يرونه من قبل.
                  </p>
                  <p className='jumbo4text2'>
                    سوف تؤثر هذه الرؤية على الجميع بطريقتهم الخاصة دون شك ...
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy4.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/470148.41d73f57.jpg'
                    visionPhilanthropy4
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Video 4 */}

          <div className='video4'>
            <div>
              <Jumbotron.VideoText>
                <p className='moreLineHeight'>
                  الهدف الأسمى الذي أسعى له هو إنشاء هيكل إيجابي "ما يعود على
                  المجتمع" حيث يعود بالمنفعة والفائدة على الجميع.
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                className={size.width < 1000 ? 'margin_vid4' : 'moreMarginTop'}
                style={{ height: 'auto' }} //temprory
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578494?h=782f79888d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='139740.9cc14f042.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef4.current.muted = !videoRef4.current.muted)}
          >
            unMute
          </button> */}
            </div>
          </div>

          {/* Video 5 */}
          <div className='video5'>
            <div>
              <Jumbotron.VideoText>
                {size.width < 400 ? (
                  <p
                    style={{ marginBottom: '65px' }}
                    className={
                      size.width < 370 ? 'text_vid_4 vid5above731' : ''
                    }
                  >
                    "أفضل وسيلة للتواصل العطاء"
                  </p>
                ) : size.width < 358 ? (
                  <p
                    style={{ marginBottom: '65px', fontSize: '0.93rem' }}
                    className={
                      size.width < 370 ? 'text_vid_4 vid5above731' : ''
                    }
                  >
                    "أفضل وسيلة للتواصل العطاء"
                  </p>
                ) : size.width < 380 ? (
                  <p
                    style={{ marginBottom: '65px', fontSize: '0.93rem' }}
                    className={
                      size.width < 370 ? 'text_vid_4 vid5above731' : ''
                    }
                  >
                    "أفضل وسيلة للتواصل العطاء"
                  </p>
                ) : size.width < 731 ? (
                  <p
                    style={{ marginBottom: '65px' }}
                    className={
                      size.width < 370 ? 'text_vid_4 vid5above731' : ''
                    }
                  >
                    "أفضل وسيلة للتواصل العطاء"
                  </p>
                ) : size.width > 731 ? (
                  <p
                    style={{ marginBottom: '65px' }}
                    className={
                      size.width < 370 ? 'text_vid_4 vid5above731' : ''
                    }
                  >
                    "أفضل وسيلة للتواصل العطاء"
                  </p>
                ) : (
                  <p
                    className={
                      size.width < 370 ? 'text_vid_4 vid5above731' : ''
                    }
                  >
                    "أفضل وسيلة للتواصل العطاء"
                  </p>
                )}
              </Jumbotron.VideoText>
              <Jumbotron.Video
                style={{ height: 'auto' }} //temprory
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578473?h=1b5721003d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='850139.6997488d.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
            </div>
          </div>

          {/* Ayusha Opus Logo */}

          <div class={`hover15 columnss`}>
            <div>
              <figure
                ref={ripple}
                onClick={() => {
                  setToggle(!toggle);
                }}
                className={`${toggle && 'rippleForMobile'}`}
              >
                <img
                  className='AO_LOGOVISION'
                  src={
                    currentTheme === 'dark'
                      ? // ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png').default
                        'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                      : // : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png').default
                        'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                  }
                  alt='AO_logo'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </figure>
            </div>
            <div className='dontshow'>
              <figure>
                <img
                  className='AO_LOGOVISION'
                  src={
                    currentTheme === 'dark'
                      ? AO_Logo_on_black
                      : // 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                        AO_Logo_on_white
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                  }
                  alt='AO_logo'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </figure>
            </div>
            <div className='dontshow'>
              <figure>
                <img
                  className='AO_LOGOVISION'
                  src={
                    currentTheme === 'dark'
                      ? AO_Logo_on_black
                      : // 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                        AO_Logo_on_white
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                  }
                  alt='AO_logo'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </figure>
            </div>
          </div>

          {/* <Jumbotron.Image
            className='AO_LOGO_VISION'
            src={
              currentTheme === 'dark'
                ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png')
                    .default
                : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png')
                    .default
            }
            alt='AO_logo'
            onContextMenu={(e) => e.preventDefault()}
            onContextMenu={(e) => e.preventDefault()}
          /> */}
          <br />
          <Jumbotron.Container className='logo_credits'>
            <Jumbotron.Text className='bold'>
              <p style={{ fontSize: '17px' }}>بيان الرؤية </p>
            </Jumbotron.Text>
            {/* <Jumbotron.Text className='center'>
          <p>Ayusha Opus ~فن العطاء ῀ العودة إلى المجتمع ~</p>
        </Jumbotron.Text>
        <Jumbotron.Text className='center'>
          <p>4.	توفير القوت والغذاء للبشرية جمعاء ~ Alpha and Omega</p>
        </Jumbotron.Text> */}
            <Jumbotron.Text className='center'>
              <p style={{ fontSize: '15.4px' }}>Ayusha Opus</p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center credit1'>
              <p style={{ fontSize: '15.4px' }}>
                {' '}
                فن العطاء ῀ما يعود على المجتمع
              </p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center'>
              <p style={{ fontSize: '15.4px' }}>
                توفير القوت والغذاء للبشرية جمعاء{' '}
              </p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center credit2'>
              <p style={{ fontSize: '15.4px' }}>ألفا وأوميجا.</p>
            </Jumbotron.Text>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Desktop-Container'>
        {/* Jumbotron 1 */}
        <div className='Jumbo'>
          {/* Jumbotron 1 */}
          <Jumbotron.Container>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    كثير من الأعمال التي أقوم بها والمرتبطة ارتباطاً وثيقاً
                    بنطاق رؤيتي الرامية إلى المساعدة في انهاء الفقر المدقع
                    والجوع الذي يعاني منه العالم وخاصة أطفالنا.
                  </p>
                  <p>
                    أحث على بناء شراكات وعلاقات مع أفراد خاصين من جميع أنحاء
                    العالم تكون لديهم الرغبة في تقديم المساعدة لحل هذه المعضلة
                    التي تعاني منها البشرية جمعاء.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy1.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/915689.afcf01867.jpg'
                    visionPhilanthropy1
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 1 */}

          <Jumbotron.Container className='video1'>
            <Jumbotron direction='column'>
              <Jumbotron.Video
                className='moreMarginTop'
                style={{ height: 'auto' }} //temprory
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578638?h=a47c0a4d23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='814064.3b2caa22.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef1.current.muted = !videoRef1.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='vision2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    نحن نعيش في عالم يتسم بالرفاهية والرخاء المعيشي، في عالم لا
                    يجب أن تكون مسألة افتقار القوت قضية شائكة، إلا أننا نرى كل
                    يوم الكثير من حول العالم يعانون بشدة من الفقر والجوع.
                  </p>
                  <p>
                    كي أكون قدوةً ومثالاً يتبعه الجميع، أتعهد بأن يكون "الاحسان/
                    الصدقة" جزء كبير من عوائدي التي أتبرع بها في صندوق التمويل
                    وسوف تتجه نحو حل هذه المشكلة من أجل التخفيف من المعاناة.
                  </p>
                  <p>
                    وجب علينا الشعور بالمسؤولية تجاه إخواننا البشر بغض النظر عن
                    العرق، العقيدة أو الدين..
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy2.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/814315.e2a28988.jpg'
                    visionPhilanthropy2
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 2 */}

          <Jumbotron.Container className='video2'>
            <Jumbotron direction='column'>
              <Jumbotron.VideoText>
                {size.width < 1012 ? (
                  <p
                    className='moreLineHeight'
                    style={{ marginBottom: '50px' }}
                  >
                    تكمن الفضيلة الرئيسية لريادة الأعمال الاجتماعية في "فن
                    العطاء"
                  </p>
                ) : size.width < 732 ? (
                  <p
                    className='moreLineHeight'
                    style={{ marginBottom: '80px' }}
                  >
                    تكمن الفضيلة الرئيسية لريادة الأعمال الاجتماعية في "فن
                    العطاء"
                  </p>
                ) : (
                  <p className='moreLineHeight'>
                    تكمن الفضيلة الرئيسية لريادة الأعمال الاجتماعية في "فن
                    العطاء"
                  </p>
                  // <></>
                )}

                {/* <p className={size.width < 1000 ? 'margin-10' : ''}>
              …Sara Al Madani
            </p> */}
              </Jumbotron.VideoText>
              <Jumbotron.Video
                className={size.width < 1000 ? 'margin_vid2' : ''}
                style={{ height: 'auto' }} //temprory
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578429?h=5d93e73319&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='513406.74833a66.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef2.current.muted = !videoRef2.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    الجزء الأعظم من القيم الروحية التي أتمتع بها هو جمع الناس مع
                    بعضهم البعض في روح من العطاء، مع العلم إننا بحاجة إلى
                    التفكير بوعي شامل كأسرة إنسانية واحدة وليس كفرد واحد من أجل
                    القدرة على حل مشاكل العالم.
                  </p>
                  <p>
                    يرجى مساعدتي ومساعدة العالم لأن يكونوا مع بعضهم البعض كأسرة
                    إنسانية بوعي جماعي من أجل تحقيق هذا الحلم إلى واقع مزهر.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy3.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/202975.e43a1730.jpg'
                    visionPhilanthropy3
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 3 */}

          <Jumbotron.Container className='video3'>
            <Jumbotron direction='column'>
              <Jumbotron.VideoText>
                <p className='moreLineHeight'>
                  يتردد صدى إيماني ورؤيتي في قيمي الأخلاقية لجلب القوت والغذاء
                  لهؤلاء الذين هم بحاجة ماسة إليه عبر الاهتزاز الإيجابي الذي
                  سينبعث منه فني.
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                style={{ height: 'auto' }} //temprory
                className={size.width < 1000 ? 'margin_vid3' : 'marginTop35'}
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578577?h=53ee1a0d72&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='438201.12a0e317.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>

                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef3.current.muted = !videoRef3.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 4 */}
          <Jumbotron.Container>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    أبتكر الفن مع رؤية تجميل العالم من حولنا بطرق متعددة، رائعة
                    وذات مغزى.. إلى الأفراد الذين حالفهم الحظ لشراء وتقدير هذا
                    الفن وإلى العديد من الأفراد الذي لم يرونه من قبل.
                  </p>
                  <p className='jumbo4text2'>
                    سوف تؤثر هذه الرؤية على الجميع بطريقتهم الخاصة دون شك ...
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={
                    // 'https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/vision&philanthropy4.jpg'
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/470148.41d73f57.jpg'
                    visionPhilanthropy4
                  }
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 4 */}

          <Jumbotron.Container className='video4'>
            <Jumbotron direction='column'>
              <Jumbotron.VideoText>
                <p className='moreLineHeight'>
                  الهدف الأسمى الذي أسعى له هو إنشاء هيكل إيجابي "ما يعود على
                  المجتمع" حيث يعود بالمنفعة والفائدة على الجميع.
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                style={{ height: 'auto' }} //temprory
                className={size.width < 1000 ? 'margin_vid4' : 'marginTop35'}
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578494?h=782f79888d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='139740.9cc14f042.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef4.current.muted = !videoRef4.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Video 5 */}

          <Jumbotron.Container>
            <Jumbotron direction='column'>
              <Jumbotron.VideoText>
                <p className={size.width < 370 ? 'text_vid_4' : ''}>
                  "أفضل وسيلة للتواصل العطاء"
                </p>
              </Jumbotron.VideoText>
              <Jumbotron.Video
                style={{ height: 'auto' }} //temprory
                // style={{ height: 'auto' }}  //temprory
              >
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src='https://player.vimeo.com/video/671578473?h=1b5721003d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;muted=1&amp;autoplay=1'
                    frameborder='0'
                    allow='autoplay; fullscreen; picture-in-picture'
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title='850139.6997488d.mp4'
                  ></iframe>
                </div>
                <script src='https://player.vimeo.com/api/player.js'></script>
                {/* <div className='overlayVideo'></div> */}
              </Jumbotron.Video>
              {/* <button
            onClick={() => (videoRef5.current.muted = !videoRef5.current.muted)}
          >
            unMute
          </button> */}
            </Jumbotron>
          </Jumbotron.Container>

          {/* Ayusha Opus Logo */}

          <div class='hover15 columnss'>
            <div>
              <figure>
                <img
                  className='AO_LOGOVISION'
                  src={
                    currentTheme === 'dark'
                      ? AO_Logo_on_black
                      : // 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                        AO_Logo_on_white
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                  }
                  alt='AO_logo'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </figure>
            </div>
            <div className='dontshow'>
              <figure>
                <img
                  className='AO_LOGOVISION'
                  src={
                    currentTheme === 'dark'
                      ? AO_Logo_on_black
                      : // 'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                        AO_Logo_on_white
                    // 'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                  }
                  alt='AO_logo'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </figure>
            </div>
            <div className='dontshow'>
              <figure>
                <img
                  className='AO_LOGOVISION'
                  src={
                    currentTheme === 'dark'
                      ? // ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png').default
                        'https://ayushaopus.eu-central-1.linodeobjects.com/762098.2bf20b10.png'
                      : // : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png').default
                        'https://ayushaopus.eu-central-1.linodeobjects.com/841209.d5c9946e.png'
                  }
                  alt='AO_logo'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </figure>
            </div>
          </div>

          {/* <Jumbotron.Image
            className='AO_LOGO_VISION'
            src={
              currentTheme === 'dark'
                ? require('../../assets/img/vision&philanthropy/AO_Logo_on_black.png')
                    .default
                : require('../../assets/img/vision&philanthropy/AO_Logo_on_white.png')
                    .default
            }
            alt='AO_logo'
            onContextMenu={(e) => e.preventDefault()}
            onContextMenu={(e) => e.preventDefault()}
          /> */}

          <Jumbotron.Container className='logo_credits'>
            <Jumbotron.Text className='bold'>
              <p>بيان الرؤية </p>
            </Jumbotron.Text>
            {/* <Jumbotron.Text className='center'>
          <p>Ayusha Opus ~فن العطاء ῀ العودة إلى المجتمع ~</p>
        </Jumbotron.Text>
        <Jumbotron.Text className='center'>
          <p>4.	توفير القوت والغذاء للبشرية جمعاء ~ Alpha and Omega</p>
        </Jumbotron.Text> */}
            <Jumbotron.Text className='center'>
              <p>Ayusha Opus</p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center credit1'>
              <p>فن العطاء ῀ ما يعود على المجتمع</p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center'>
              <p>توفير القوت والغذاء للبشرية جمعاء </p>
            </Jumbotron.Text>
            <Jumbotron.Text className='center credit2'>
              <p>ألفا وأوميجا.</p>
            </Jumbotron.Text>
          </Jumbotron.Container>
        </div>
      </div>
    </div>
  );
}
