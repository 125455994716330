import Layout from './Components/Layout';
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from './context/globalContext';
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import { Route } from 'react-router';
import Project from './pages/Project';
import MainVideo from './pages/mainVideo';
import Teaser from './pages/teaser';
import Scenes from './pages/scenes';
import Artist from './pages/artist';
import Meaning from './pages/meaning';
import Philosophy from './pages/philosophy';
import Beauty from './pages/beauty';
import Metal from './pages/metal';
import Applications from './pages/applications';
import Commitment from './pages/commitment';
import Contact from './pages/contact';
import Vision from './pages/vision';
import Copyright from './pages/copyright';
import ScrollToTop from './ScrollToTop';
import { createGlobalStyle } from 'styled-components/macro';
//Font
// import CaviarDreams from './assets/fonts/CaviarDreams-webfont.woff';
import CaviarDreams from './assets/fonts/CaviarDreams.ttf';
import Thin from './assets/fonts/HelveticaNeue-Thin.woff';
import arabic_regular from './assets/fonts/qatar-regular.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'arabic_regular';
    src: url(${arabic_regular}) format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  body {
    @font-face {
      font-family: 'arabic_regular';
      src: url(${arabic_regular}) format('ttf');
      font-weight: normal;
      font-style: normal;
    }
  }
  // @font-face {
  //   font-family: 'caviar_dreams';
  //   src: url(${CaviarDreams}) format('ttf');
  //   font-weight: normal;
  //   font-style: normal;

  // }

  @font-face {
    font-family: 'Helvertica Neue';
    font-style: normal;
    font-weight: normal;
    src: local('Helvertica Neue'), url(${Thin}) format("woff");
  }
`;

function App(props) {
  const { cursorStyles } = useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();
  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };
  const [toggleMenu, setToggleMenu] = useState(false);
  const [linkSection, setLinkSection] = useState(false);
  const moveHeaderUp = false;
  return (
    <BrowserRouter>
      <GlobalStyle />
      <ScrollToTop>
        <Route path={'/'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={false}
          >
            <Home
              setLinkSection={setLinkSection}
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/scenes'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Scenes onCursor={onCursor} />
          </Layout>
        </Route>

        <Route path={'/artist'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Artist
              setLinkSection={setLinkSection}
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/meaning'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Meaning
              setLinkSection={setLinkSection}
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/philosophy'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Philosophy
              setLinkSection={setLinkSection}
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/beauty'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Beauty
              setLinkSection={setLinkSection}
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/metal'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Metal
              setLinkSection={setLinkSection}
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/commitment'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Commitment
              setLinkSection={setLinkSection}
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/contact'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Contact
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/vision-philanthropy'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Vision
              setLinkSection={setLinkSection}
              onCursor={onCursor}
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          </Layout>
        </Route>

        <Route path={'/main-video'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <MainVideo setLinkSection={setLinkSection} onCursor={onCursor} />
          </Layout>
        </Route>

        <Route path={'/teaser'} exact>
          <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          >
            <Teaser setLinkSection={setLinkSection} onCursor={onCursor} />
          </Layout>
        </Route>

        <Route path={'/copyright'} exact>
          {/* <Layout
            toggleMenu={toggleMenu}
            linkSection={linkSection}
            setToggleMenu={setToggleMenu}
            moveHeaderUp={true}
          > */}
          <Copyright setLinkSection={setLinkSection} onCursor={onCursor} />
          {/* </Layout> */}
        </Route>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
