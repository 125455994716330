import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'normalize.css';
import reportWebVitals from './reportWebVitals';
import { GlobalProvider } from './context/globalContext';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

// //Importing Firebase
import firebase from "../src/firebase/index.js";
import 'firebase/firestore';
import 'firebase/auth';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
