import styled from 'styled-components/macro';

export const Container = styled.div``;

export const Group = styled.div`
  margin: 60px 20px 0px 20px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1200px) {
    // width: 800px;
    flex-flow: wrap;
    justify-content: center;

    &:first-of-type {
      margin-left: 0px;
    }
    &:last-of-type {
      margin-right: 0px;
    }
  }

  &:first-of-type {
    @media (max-width: 1600px) {
      margin-left: 110px;
    }
  }
  &:last-of-type {
    @media (max-width: 1600px) {
      margin-right: 110px;
    }
  }
`;

export const Image = styled.img`
  border: 0;
  width: 100%;
  max-width: 305px;
  height: auto;
  padding: 0;
  margin: 20px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(2.3);
    z-index: 99;
    margin: 20px;
  }

  @media (max-width: 1200px) {
    &:hover {
      transform: scale(1.4);
      z-index: 99;
      margin: 20px;
    }
  }

  &:first-of-type {
    margin-left: 56px;

    @media (max-width: 1500px) {
      margin-left: 45px;
    }

    @media (max-width: 1200px) {
      &:first-of-type {
        margin-left: 20px;
      }
    }
  }

  &:last-of-type {
    margin-right: 56px;

    @media (max-width: 1500px) {
      margin-right: 45px;
    }
  }

  @media (max-width: 1200px) {
    &:last-of-type {
      margin-right: 20px;
    }
  }
`;
