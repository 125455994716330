import styled from 'styled-components/macro';

export const ProjectVideosSection = styled.section``;

export const Video = styled.section`
  position: relative;
  width: 100%;
  height: 480px;
  margin: 156px 0 215px;
  display: block;
  overflow: hidden;

  p {
    font-size: 1.6rem;
    font-weight: 700;
    color: ${(props) => props.theme.text};
    span {
      font-size: 2rem;
      font-weight: 700;
    }
  }

  video {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }
`;
