import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

export const HeaderNav = styled(motion.div)`
  height: 0;
  width: 100%;
  position: sticky;
  top: 72px;
  right: 0;
  left: 0;
  z-index: 99;
  transition: transform 0.7s cubic-bezier(0.666, 0, 0.237, 1);
  transform: translateY(0px);

  &.moveHeaderUp {
    transition: transform 0.7s cubic-bezier(0.666, 0, 0.237, 1);
    transform: translateY(-140px) !important;
  }

  @media (max-width: 768px) {
    // height: 165px;
    background: ${(props) => props.theme.background};
    top: 0;
  }
`;

export const Logo = styled.div`
  a {
    font-size: 1.6rem;
    font-weight: 700;
    color: ${(props) => props.theme.text};
  }

  .poloySVG {
    width: 70px;
    top: -23px;
    left: 17px;
    @media (max-width: 768px) {
      width: 65px;
      top: 35px;
      left: 12px;
    }
    @media (max-width: 500px) {
      width: 55px;
      top: 43px;
      left: 9px;
    }
  }

  svg {
    width: 140px;
    top: -55px;
    left: -20px;
    position: absolute;

    @media (max-width: 768px) {
      width: 130px;
      top: 4px;
      left: -22px;
    }

    @media (max-width: 500px) {
      width: 110px;
      top: 17px;
      left: -20px;
    }

    g {
      g {
        path {
          fill: ${(props) => props.theme.text};
        }
      }
    }
  }

  span {
    height: 1rem;
    width: 1rem;
    background: ${(props) => props.theme.red};
    margin: 0 4px;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    bottom: 2px;
  }
`;
export const Menu = styled.div`
  margin-right: 80px;

  button {
    transform-origin: center;
    border: none;
    padding: 20px;
    background: none;
    outline: none;
    position: absolute;
    top: -28px;
    right: -28px;
    @media (max-width: 768px) {
      right: -27px;
      top: 28px;
    }
    @media (max-width: 500px) {
      right: -27px;
      top: 28px;
    }

    span {
      width: 44px;
      height: 3px;
      display: block;
      background: ${(props) => props.theme.text};
      margin: 8px;
    }
  }
`;
