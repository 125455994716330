import styled, { css } from 'styled-components/macro';

export const FormContainer = styled.form`
  margin: 150px 0 0;

  h2 {
    text-align: center;
    font-size: 3rem;
    color: ${(props) => props.theme.text};
    margin: 0 0 60px;
    @media (max-width: 500px) {
      font-size: 2.7rem;
    }
  }
`;
export const Row = styled.div`
  display: flex;
  // width: 1000px;
  margin: 0 auto;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    width: 100%;
  }

  button {
    background: linear-gradient(
      60deg,
      #9c192f,
      20%,
      #f42835,
      40%,
      #aa0034,
      60%,
      #eb002a,
      80%,
      #b1212b 100%
    ) !important;
    outline: none;
    border: none;
    padding: 15px 40px;
    color: ${(props) => props.theme.text};
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0 auto;
  }
`;
export const Col = styled.div`
  width: calc((100% / 2) - 30px);
  margin: 0 0 45px;
  @media (max-width: 768px) {
    display: block;
    box-sizing: border-box;
  }
  @media (max-width: 500px) {
    display: block;
    width: 100%;
  }

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  input,
  textarea {
    width: 100%;
    height: 45px;
    padding: 4px 7px;
    box-sizing: border-box;
    text-align: right;
    border: none;
    font-size: 1.4rem;
    outline: none;
    /* border-bottom: 3px solid  ${(props) => props.theme.red};;
    border-left: 3px solid  ${(props) => props.theme.red};;
    border-right: 3px solid  ${(props) => props.theme.red};;*/

    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
  }

  textarea {
    height: 150px;
  }
`;

export const ColFull = styled.div`
  width: 100%;
  margin: 0 0 45px;
  @media (max-width: 768px) {
    display: block;
    box-sizing: border-box;
  }
  @media (max-width: 500px) {
    display: block;
    width: 100%;
  }

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  input,
  textarea {
    width: 100%;
    height: 45px;
    padding: 4px 7px;
    box-sizing: border-box;
    border: none;
    outline: none;
    /* border-bottom: 3px solid  ${(props) => props.theme.red};;
    border-left: 3px solid  ${(props) => props.theme.red};;
    border-right: 3px solid  ${(props) => props.theme.red};;*/
  }

  textarea {
    height: 150px;
  }
`;
