import React from 'react';
import Jumbotron from '../../Components/jumbotron/index';
// import beauty1 from '../../assets/img/beauty/beauty1.jpg';
// import beauty2 from '../../assets/img/beauty/beauty2.jpg';
// import beauty3 from '../../assets/img/beauty/beauty3.jpg';

export function JumbotronContainer() {
  const beauty1 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/709135.47f4eac30.jpg';
  const beauty2 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg';
  const beauty3 =
    'https://ayushaopus.eu-central-1.linodeobjects.com/243708.8f7e8571t.jpg';

  return (
    <>
      <div className='Desktop-Container'>
        <div className='Jumbo'>
          <Jumbotron.Container className='beauty1'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    لا شك أن حياتك تم تشكيلها بطريقة معينة وكانت النتيجة احضارك
                    إلى هنا؛ وحان الوقت الآن لاختيار قطعة إبداعية فاتنة من قطعة
                    نادرة التصميم. شي تشعر بتردد صداه مع طاقتك الشخصية واهتزازك
                    ῀!
                  </p>
                  <p>
                    أضفي الجمال على بيئتك المحيطة وأعد إنشاءها. فن حديث غير
                    مألوف من أجلك ومن أجل الآخرين للشعور والاستمتاع به.
                  </p>
                  <p>شهادة نجاح صادقة وراسخة!</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/709135.47f4eac30.jpg'
                  src={beauty1}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 2 */}
          <Jumbotron.Container className='beauty2'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    تتسم الروائع الفنية هذه بحس صناعي أولي لا يمكن الشعور
                    والاحساس به إلا بمثل هذا الحجم والوزن.
                  </p>
                  <p>
                    يمكنني تصميم وابتكار العديد من الزخارف الفنية الإبداعية التي
                    لا مثيل لها وستصبح واحدة من أنواع الروائع الفنية البهية
                    المصممة خصيصاً لك!
                  </p>
                  <p>
                    لا تقارن هذه المنحوتات الفنية المتشابكة بأي شيء آخر عندما
                    تتطلع إلى إعادة تكوين الطاقة من حولك بهذا الحجم.
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={beauty2}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          {/* Jumbotron 3 */}
          <Jumbotron.Container className='beauty3'>
            <Jumbotron direction='row'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>
                    تخيل أنك تقف بجانب منحوتة شاهقة يبلغ حجمها 10 قدم ووزنها
                    أكثر من 2000 رطل! (+ 900 كيلوجرام) ῀!
                  </p>
                  <p>
                    لا تقارن الكلمات"راسخة" و"متينة" بأي شيء آخر عند وضع مثل هذه
                    القطع في بهوك، فندقك أو غرفة الصلاة الخاصة بك؛ قد تصف هذه
                    الكلمات طبيعتك وعزيمتك.
                  </p>
                  <p>
                    بناءً على الرؤية الخاصة بك، يمكن مشاهدة هذه الابداعات الفنية
                    والشعور بها كقطعة فنية تحفيزية كل يوم!
                  </p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
              <Jumbotron.Pane>
                <Jumbotron.Image
                  src={beauty3}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/243708.8f7e8571t.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty3.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>

          <Jumbotron.Container className='beauty4'>
            <Jumbotron direction='row-reverse'>
              <Jumbotron.Pane>
                <Jumbotron.Text>
                  <p>إمكانيات صادقة لا حدود لها... دع خيالك يُجمل مكانك῀!</p>
                </Jumbotron.Text>
              </Jumbotron.Pane>
            </Jumbotron>
          </Jumbotron.Container>
        </div>
      </div>

      <div className='Mobile-Container'>
        <div className='Jumbo'>
          <div className='beauty1'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    لا شك أن حياتك تم تشكيلها بطريقة معينة وكانت النتيجة احضارك
                    إلى هنا؛ وحان الوقت الآن لاختيار قطعة إبداعية فاتنة من قطعة
                    نادرة التصميم. شي تشعر بتردد صداه مع طاقتك الشخصية واهتزازك
                    ῀!
                  </p>
                  <p>
                    أضفي الجمال على بيئتك المحيطة وأعد إنشاءها. فن حديث غير
                    مألوف من أجلك ومن أجل الآخرين للشعور والاستمتاع به.
                  </p>
                  <p>شهادة نجاح صادقة وراسخة!</p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty1.jpg'
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/709135.47f4eac30.jpg'
                  src={beauty1}
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 2 */}
          <div className='beauty2'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    تتسم الروائع الفنية هذه بحس صناعي أولي لا يمكن الشعور
                    والاحساس به إلا بمثل هذا الحجم والوزن.
                  </p>
                  <p>
                    يمكنني تصميم وابتكار العديد من الزخارف الفنية الإبداعية التي
                    لا مثيل لها وستصبح واحدة من أنواع الروائع الفنية البهية
                    المصممة خصيصاً لك!
                  </p>
                  <p>
                    Nothing comes close to these intricate carvings when you’re
                    looking to re-create the energy around you with this
                    magnitude.
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={beauty2}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/914660.f05bef817.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty2.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />

                <div className='overlay'></div>
              </div>
            </div>
          </div>

          {/* Jumbotron 3 */}
          <div className='beauty3'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>
                    تخيل أنك تقف بجانب منحوتة شاهقة يبلغ حجمها 10 قدم ووزنها
                    أكثر من 2000 رطل! (+ 900 كيلوجرام) ῀!
                  </p>
                  <p>
                    لا تقارن الكلمات"راسخة" و"متينة" بأي شيء آخر عند وضع مثل هذه
                    القطع في بهوك، فندقك أو غرفة الصلاة الخاصة بك؛ قد تصف هذه
                    الكلمات طبيعتك وعزيمتك.
                  </p>
                  <p>
                    بناءً على الرؤية الخاصة بك، يمكن مشاهدة هذه الابداعات الفنية
                    والشعور بها كقطعة فنية تحفيزية كل يوم!
                  </p>
                </Jumbotron.Text>
              </div>
              <div>
                <Jumbotron.Image
                  src={beauty3}
                  // src = 'https://ayushaopus.eu-central-1.linodeobjects.com/243708.8f7e8571t.jpg'
                  // src='https://ayusha-opus.s3.eu-west-2.amazonaws.com/static/media/beauty3.jpg'
                  alt=''
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className='overlay'></div>
              </div>
            </div>
          </div>

          <div className='beauty4'>
            <div>
              <div>
                <Jumbotron.Text>
                  <p>إمكانيات صادقة لا حدود لها... دع خيالك يُجمل مكانك῀!</p>
                </Jumbotron.Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
